/* app/ui/form/auto-complete */

define(
	[
		'jquery',
		'templayed'
	],
	function($, templayed) {

		'use strict';

		var AutoComplete;
		var selectors = {
			input: '.js-auto-complete',
			container: '.js-auto-complete-container',
			results: '.js-auto-complete__list'
		};

		var $inputs = null;
		var template = '<ul class="auto-complete__list js-auto-complete__list hidden">{{#items}}<li class="hidden" data-filter="{{FilterValue}}" data-cy="autoCompleteResult"><a href="{{Url}}" class="auto-complete__link" title="{{Name}}">{{Name}}</a></li>{{/items}}</ul>';

		return {
			init: function() {
				AutoComplete = this;
				$inputs = $(selectors.input);

				AutoComplete._initEvents();
			},

			// Listen for keyup event on auto complete inputs
			_initEvents: function () {
				$inputs.on('keyup', AutoComplete._onInputTyping);
				$inputs.on('blur', AutoComplete._resetInputResults);
				$inputs.on('keypress', AutoComplete._stopEnter);
				$inputs.on('keydown', AutoComplete._arrows);
				$(selectors.container).find('button').on('click', AutoComplete._stopClick);
			},

			_resetInputResults: function () {
				var $input = $(this);
				AutoComplete._clearTimer($input);
				var timer = setTimeout(function(){
					var $container = $input.closest(selectors.container);
					var $results = $container.find(selectors.results);
					$results.addClass('hidden');
				}, 500);
				$input.data('timer', timer);
			},

			_clearTimer: function($input) {
				var timer = $input.data('timer');
				if (timer) {
					clearTimeout(timer);
					$input.data('timer', null);
				}
			},

			_onInputTyping: function (event) {
				var $activeInput = $(this);
				var $container = $activeInput.closest(selectors.container);
				var $results = $container.find(selectors.results);

				if ($results.length) {
					var value = $.trim($activeInput.val().toLowerCase());

					if (value.length > 1) {
						AutoComplete._filterInputResults($results, value);
					}

					if (value.length === 0) {
						$results.addClass('hidden');
					}
				} else {
					AutoComplete._fetchInputResults($container);
				}
			},

			_stopEnter: function (event) {
				if(event.keyCode == 13) {
					event.preventDefault();
					var $activeInput = $(this);
					var $container = $activeInput.closest(selectors.container);
					var $results = $container.find(selectors.results);
					var $link = $results.find('li').filter('.selected').find('a');
					if ($link.length) {
						location.href = $link.attr('href');
					}
				}
			},

			_stopClick: function (event) {
				event.preventDefault();
				var $input = $(this).closest(selectors.container).find(selectors.input);
				AutoComplete._clearTimer($input);
				$input.focus();
				AutoComplete._onInputTyping.call($input[0]);
			},

			_arrows: function (event) {
				var diff = 0;
				switch(event.keyCode) {
					case 38:
						diff = -1;
						break;
					case 40:
						diff = 1;
						break;
				}

				if (diff) {
					event.preventDefault();
					var $activeInput = $(this);
					var $container = $activeInput.closest(selectors.container);
					var $results = $container.find(selectors.results);
					var $items = $results.find('li').filter(':not(.hidden)');
					var $selected = $items.filter('.selected');

					if ($selected.length) {
						var ind = $.inArray($selected[0], $items);
						ind += diff;
						ind = Math.max(0,ind);
						ind = Math.min(ind, $items.length-1);
						$selected.removeClass('selected');
						$items.eq(ind).addClass('selected');
					} else {
						$items.eq(0).addClass('selected');
					}
				}
			},

			_filterInputResults: function ($results, value) {
				$results.find('.js-no-results').remove();
				var $matches = $results.find('li').filter( AutoComplete._getFilter(value) );
				$results.removeClass('hidden');

				if (!$matches.length) {
					$results.append('<li class="js-no-results no-results">No Results</li>');
				}
			},

			_getFilter: function(value) {
				return function(){
					var $item = $(this);
					var itemFilter = $item.data('filter') || '';
					var itemText = $item.text();
					var terms = value.split(/\s+/);
					var termsCount = 0, matchCount = 0;

					for (var i = 0; i < terms.length; i++) {
						if( terms[i] ){
							termsCount ++;
							var matchIndex = itemFilter.indexOf(terms[i]);
							if(matchIndex != -1 ){
								matchCount++;
								itemText = itemText.replace(new RegExp('('+terms[i]+')', 'ig'), '<b>$1</b>');
							}
						}
					}

					$item.find('a').html(itemText);

					if (termsCount == matchCount) {
						$item.removeClass('hidden');
						return true;
					} else {
						$item.addClass('hidden').removeClass('selected');
						return false;
					}
				};
			},

			_fetchInputResults: function ($container) {
				var ajaxUrl = $container.attr('data-ajax-url');

				if ($container.data('requesting')) {
					return;
				}

				$container.data('requesting', true);

				if (ajaxUrl) {
					$.ajax({
						url: ajaxUrl,
						dataType: 'json'
					}).done(
						$.proxy(AutoComplete._insertInputResults, $container)
					).fail(
						$.proxy(AutoComplete._requestFailure, $container)
					);
				}
			},

			_insertInputResults: function (data) {
				var $container = this;
				var results = '';
				var items = [];

				for (var i = 0; i < data.length; i++) {
					data[i].FilterValue = data[i].Name.toLowerCase();
					items.push(data[i]);
				}

				results = templayed(template)({ items: items });
				$container.append(results);
				$container.data('requesting', false);
				$container.find(selectors.input).trigger('keyup');
			},

			_requestFailure: function() {
				this.data('requesting', false);
				//console.log('failed');
			}
		};
	}
);