/* util/ajax */

import 'pubsub';

class AjaxResolver {
    constructor() {
        this.cache = {};
    }

    init() {
        $.subscribe( '/ajax/get', this._processRequest );
        // work around use of "apply"
        window.topics.ajaxPubSub = window.topics.ajaxPubSub || this;
    }
    _processRequest(data) {
        // work around use of "apply"
        let _this = this || window.topics.ajaxPubSub;
        if(data instanceof Array){
            data = data[0];
        }
        let callback = _this._generateCallback(data.url, data.id, data.href, data.callback);
        _this._getContent(data.url, data.id, data.href, callback);
    }

    _generateCallback(url, id, href, callback) {
        if(!callback)
        {
            return response => {
                this.cache[url] = {content: response};
                this._publishResponseEvent(id, href, response);
            };
        }
        else
        {
            return response => {
                this._publishResponseEvent(id, href, response);
                callback(response);
            };
        }

    }

    _getContent( url, id, href, callback ) {
        if ( this.cache[url] !== undefined ) {
            const response = this.cache[url].content;
            this._publishResponseEvent( id, href, response );
        } else {
            $.ajax( {
                url: url,
                success: callback
            } );
        }
    }

    _publishResponseEvent (id, href, response) {
        $.publish( '/ajax/ready/' + id, { html: response, href: href } );
    }
}

const ajaxPubSub = new AjaxResolver();
export default ajaxPubSub;
