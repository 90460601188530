/* app/page/all */
import Vimeo from 'app/ui/video/vimeo';

import $ from 'jquery';
import LazyLoad from 'util/lazyload';
import Ajax from 'util/ajax';
import Header from 'app/ui/nav/load';
import VideoLightbox from 'app/ui/video/lightbox';
import Tooltip from 'app/ui/tooltip/tooltip';
import AutoComplete from 'app/ui/form/auto-complete';
import SocialShare from 'app/ui/social/share';
import MobileShowMore from 'app/ui/mobile/show-more';
import BillProgress from 'app/ui/bill-progress/bill-progress';
import MapLoad from 'app/ui/map/map.load';
import Youtube from 'app/ui/video/youtube';
import AccessibleKeyboard from 'app/ui/accessibility/keyboard';
import GoogleRecaptcha from 'app/ui/form/google-recaptcha';
import 'pubsub';
import 'tablescroll';
import 'throttledebounce';
import 'appendAround';
import 'unobtrusiveAjax';

// Init Utility Functions

LazyLoad.init();
Ajax.init();
Header.init();
VideoLightbox.init();
Tooltip.init();
AutoComplete.init();
SocialShare.init();
MobileShowMore.init();
AccessibleKeyboard.init();

// Init appendaround
$('html').find('.js-appendaround').appendAround();

var $body = $('body');

// Publish Resize event
var publishResizeEvent = function() {
    $.publish('/window/resize');
};

// Metadata
var openMetadataWindow = function(event) {
    event.preventDefault();
    window.open(this.href, 'Metadata', 'height=550, width=500, addressBar=no, toolbar=no, menubar=no, status=0, location=0, resizable=yes');
};

// Maps
var $maps = $('.js-map');
if ($maps.length) {
    MapLoad.init($maps);
}

//vimeo video thumbnail
//var $vimeoThumbnail = $('.js-vimeo-thumbnail');
//if ($vimeoThumbnail.length) {
//	Vimeo.init($vimeoThumbnail);
//}
Vimeo.init();
Youtube.init();

var $captcha = $('#google-recaptcha');
if ($captcha.length) {
    // Which version?
    var version = $captcha.data('googleRecaptchaVersion');
    GoogleRecaptcha.init($captcha);
} else {
    // No captcha configured so just immediately validate
    $.subscribe('/captcha/validate', function () {
        $.publish('/ajax-recaptcha/pass');
    });
}

var processHot = function(e) {
    const link = $(this).find('a[href]').first()[0] || null;
    if (!link || $(e.target).is('a')) {
        return;
    }
    e.preventDefault();
    const linkTarget = (e.which === 2 || link.target === '_blank') ? '_blank' : '_self';
    window.open(link.href, linkTarget);
};

// Events
$(window).on('resize', $.throttle(250, publishResizeEvent));

$body.on('click', '.js-hot', processHot);
$body.on('click', '.js-metadata', openMetadataWindow);

$('.js-scroll-top').on('click', function (e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, "slow");
        });

$body.on('click', '.js-limit-btn', function (e) {
    e.preventDefault();
    const $this = $(this);
    $this.siblings('.js-limit').removeClass('limit');
    $this.addClass('hidden');
});

// Check DOM for elements requiring JS
var $tables = $('.body-text').find('table');

// Table Scroll
if ($tables.length) {
    $tables.tableScroll();
}

if ($('.js-bill-progress').length) {
    BillProgress.init();
}

//for fix ios body click no work
//NZP-2117 - Sort by - touching outside the sort dropdown does not close the dropdown
var isiOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
if (isiOS === true) {

    // Store -webkit-tap-highlight-color as this gets set to rgba(0, 0, 0, 0) in the next part of the code
    var tempCSS = $('a').css('-webkit-tap-highlight-color');

    $('body').css('cursor', 'pointer')										// Make iOS honour the click event on body
            .css('-webkit-tap-highlight-color', 'rgba(0, 0, 0, 0)');		// Stops content flashing when body is clicked

    // Re-apply cached CSS
    $('a').css('-webkit-tap-highlight-color', tempCSS);

}