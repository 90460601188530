/* app/ui/form/google-recaptcha */

import $ from 'jquery';

import 'pubsub';

var GoogleRecaptcha;
var captcha;
var $captcha;

var Version = 'V2';
var SiteKeyV2 = '6Le0TkQUAAAAAMehKfVUzGBXvgGYkUf7JU3K2lZV';
var SiteKeyV3 = '6Ld5NqgZAAAAAEfolkbvtlLhdCgzcPP99gneTSD1';

class CaptchaScript {
	constructor(version){
		window.captchaOnLoad = this.onLoad;
        // v2 api (default)
        let url = 'https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=explicit';
        // v3 api
        if (version === 'V3') {
            url = 'https://www.google.com/recaptcha/api.js?onload=captchaOnLoad&render=6Ld5NqgZAAAAAEfolkbvtlLhdCgzcPP99gneTSD1';
        }
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		script.async = true;
		script.defer = true;
		this.script = document.body.appendChild(script);
	}
	onLoad (){
		GoogleRecaptcha._dependencyCheck();
	}
}

export default {
    init: function ($elm) {
        GoogleRecaptcha = this;
        $captcha = $elm;
        Version = $captcha.data('googleRecaptchaVersion');
		const csObject = new CaptchaScript(Version);
    },

    _dependencyCheck: function () {
        if (window.grecaptcha && window.grecaptcha.render && window.grecaptcha.reset) {
            GoogleRecaptcha._recaptchaInit();
            GoogleRecaptcha._initSubscriptions();
        } else {
            setTimeout(GoogleRecaptcha._dependencyCheck, 500);
        }
    },

    _initSubscriptions: function() {
        $.subscribe('googleRecapture/re-init', GoogleRecaptcha._resetCaptcha);

        $.subscribe('/captcha/validate', GoogleRecaptcha._checkCaptcha);
    },

    _resetCaptcha: function () {
		const target = $captcha.attr('id').length > 0 ? '#' + $captcha.attr('id') : '.' + $captcha.attr('class');
		$(target).empty();
        $(target).replaceWith($captcha);
        grecaptcha.reset(captcha);

    },

    _setCaptchaObject: function () {
        if (Version === 'V3') {

            // Here we prevent the default form submit so we can insert our captcha token first
			const form = $($captcha[0]).closest('form');
			$(form).submit(function (e) {
                // Only interrupt if we don't have a token already, fix for IE11
                if (!captcha) e.preventDefault();
                else return;

                grecaptcha.execute(SiteKeyV3, { action: 'submit' })
                    .then(function (token) {
                        // Set the form field with the token
                        captcha = { 'token': token };
                        console.log('v3 token received');
                        document.getElementById('g-recaptcha-response').value = token;

                        form.submit();
                    });
            });

        } else { // Fallback to V2
            captcha = grecaptcha.render($captcha[0],
                {
                    'sitekey': SiteKeyV2
                });
        }
    },

    _recaptchaInit: function () {
        if (typeof captcha === 'undefined' || captcha == null) {
            GoogleRecaptcha._setCaptchaObject();
        }
        else {
            grecaptcha.reset(captcha);
        }
    },

    _checkCaptcha: function () {
        let $errorMsg;
// Note - Version 3 does not require any user completion so it is always 'valid'. The server will verify if the user is a bot
        if (Version === 'V2' && grecaptcha.getResponse().length === 0) {
            $errorMsg = $captcha.next('[data-valmsg-for="captcha"]');
            if ($errorMsg.length) {
                $errorMsg.addClass('field-validation-error');
                $errorMsg.removeClass('field-validation-valid');
            }
            grecaptcha.reset();
        }
        else {
            $errorMsg = $captcha.next('[data-valmsg-for="captcha"]');
            if ($errorMsg.length) {
                $errorMsg.removeClass('field-validation-error');
                $errorMsg.addClass('field-validation-valid');
            }

            if (Version === 'V3') {
                grecaptcha.execute(SiteKeyV3, { action: 'check' })
                    .then(function (token) {
                        // Set the form field with the token
                        captcha = { 'token': token };
                        console.log('v3 token received');
                        document.getElementById('g-recaptcha-response').value = token;

                        $.publish('/ajax-recaptcha/pass');
                    });
            }
            else $.publish('/ajax-recaptcha/pass');
        }
    }
};