/* app/ui/diary/diary */

define(
	[
		'jquery',
		'util/mediaqueries',
		'scrollspy'
	],
	function ($, MediaQueries) {

		var BillProgress;
		var $Progress;

		return {
			init: function() {
				BillProgress = this;
				$Progress = $('.js-bill-progress');
				if ($Progress.length) {
					BillProgress._initMediaQueries();
				}
			},

			_initMediaQueries: function () {
				MediaQueries.register([
					{
						queries: MediaQueries.queries['below-768'],
						shouldDegrade: false,
						match: BillProgress._initMobile
					},
					{
						queries: MediaQueries.queries['768-plus'],
						shouldDegrade: true,
						match: BillProgress._initDesktop
					}
				]);
			},

			_initMobile: function () {
				//center the 'current' status code
				if ($('.js-bill-status-cell--current').length) {
					var currentLeftPos = $('.js-bill-status-cell--current').offset().left;
					var halfWindowWidth = $(window).width() / 2;
					var offsetCurrent = currentLeftPos - halfWindowWidth;
					if (offsetCurrent > 0) {
						$('.js-bill-progress').scrollLeft(offsetCurrent + 45);
						if ($('.js-bill-progress__swipe').length) {
							$('.js-bill-progress__swipe').css({ left: currentLeftPos + 45 });
						}
					}
				}

				//bind the scrollEvent to position the tooltip symbol trigger
				$Progress.on('scroll', function () {
					var $toolTip = $(this).find('.js-tooltip');
					if ($toolTip.length) {
						if ($toolTip.offset().left < 0) {
							$toolTip.css('padding-left', Math.abs($toolTip.offset().left)+20);
						}
					}
				});

				//only show the hand when it appears in screen
				$Progress.on('scrollSpy:enter', function () {
					if (!$Progress.hasClass('bill-progress-in-view')) {
						$Progress.addClass('bill-progress-in-view');
					}
				});
				$Progress.scrollSpy();
			},

			_initDesktop: function () {
				$Progress.off('scroll');
				var $toolTip = $Progress.find('.js-tooltip');
				if ($toolTip.length) {
					$toolTip.css('padding-left', '');
				}
			}

		};
	}
);