/* app/ui/accessibility/keyboard */

/* Provides some simple keyboard acessibility functions for common elements */
define(
	[
		'jquery'
	],
	function ($) {

		'use strict';

		var AccessKeyboard;

		return {
			init: function () {
				AccessKeyboard = this;

				AccessKeyboard.initKeyboardAccessibleLabels();
			},

			initKeyboardAccessibleLabels: function () {
				var $labels = $('.js-access-label');

				$labels.on('keypress', function (event) {
					var enter = 13;
					var space = 32;

					if (event.keyCode == enter || event.keyCode == space) {
						$(event.target).click();
					}
				});
			}
		};
	}
);