/* app/ui/video/lightbox */

define(
	[
		'jquery',
        'util/mediaqueries',
		'app/ui/video/vimeo',
		'app/ui/video/youtube',
		'pubsub',
		'magnificPopup'
	],
	function ($, MediaQueries, _Vimeo, YouTube) {

		var VideoLightbox;
		var Vimeo = _Vimeo.default || _Vimeo;
		var $body;

		var resizeVideo = function() {
			var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			var h = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60;
			var widthNeeded = Math.min(16/9*h, 1360);
			this.contentContainer.css('max-width', widthNeeded);
		};

		return {
			init: function( thisVid ) {
				VideoLightbox = this;

				$body = $('body');

				VideoLightbox._initMediaQueries();
			},

			_initMediaQueries: function () {
				MediaQueries.register([
					{
						queries: MediaQueries.queries['below-768'],
						shouldDegrade: false,
						match: VideoLightbox._initMobile
					},
					{
						queries: MediaQueries.queries['768-plus'],
						shouldDegrade: true,
						match: VideoLightbox._initDesktop
					}
				]);
			},

			_initDesktop: function () {
				// Video Lightbox
				$body.magnificPopup({
					delegate: '.js-video-full',
					type: 'iframe',
					callbacks: {
						open: resizeVideo,
						resize: resizeVideo
					}
				});

				$body.on('click', '.js-video-popup', VideoLightbox._onPopupClick);
			},

			_initMobile: function () {
				$body.off('click', '.js-video-full');
				$body.off('click', '.js-video-popup', VideoLightbox._onPopupClick);
			},

			_onPopupClick: function (event) {
				event.preventDefault();
				var src = this.dataset.ajaxUrl;
				if (!src) {
					src = this.href;
				}

				$.magnificPopup.open({
					items: {
						src: src
					},
                    type: 'ajax',
                    callbacks: {
						parseAjax: function (mfpResponse) {
							// mfpResponse.data is a "data" object from ajax "success" callback
							// for simple HTML file, it will be just String
							// You may modify it to change contents of the popup
							// For example, to show just #some-element:
							// mfpResponse.data = $(mfpResponse.data).find('#some-element');

							// mfpResponse.data must be a String or a DOM (jQuery) element
							console.log('Ajax content loaded:', mfpResponse);
							var vimeothumbnails = $(mfpResponse.data).find('.js-vimeo-thumbnail');
							if (vimeothumbnails.length) {
								Vimeo.getVimeoThumbnail(vimeothumbnails);
							}
							var youtubethumbnails = $(mfpResponse.data).find('.js-youtube-thumbnail');
							if (youtubethumbnails.length) {
								YouTube.getyoutubeThumbnail(youtubethumbnails);
							}
                        }
                    }
                });

			}
		};
	}
);