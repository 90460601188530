/* app/ui/map/map.load */

define(
	[
		'jquery',
		'app/ui/map/map.control',
		'pubsub'
	],
	function ($, MapControl) {

		var MapLoad;

		// A new Google Maps API key should be created for each new project
		var apiKey = 'AIzaSyD5FAWUaIBhzSAY32oNfvoNB0v2jzqU0GY';
		var apiLoaded = false;
		var google;
		var $maps;

		return {
			init: function ($elms) {
				MapLoad = this;
				$maps = $elms;

				// If only interactive map is on page initialise the module for this
				// The MapInteractive module will only load the API code if the map is toggled to display
				// Otherwise load API and init MapControl module
				if ($maps.length) {
					MapControl.init($maps);
					MapLoad.loadApi();
				}
			},

			// Load Google Maps API version 3
			loadApi: function () {
				$.getScript('https://www.google.com/jsapi', function () {
					window.google.load('maps', '3', {
						other_params: '&key=' + apiKey,
						callback: MapLoad.mapApiLoaded
					});
				});
			},

			// Callback function once API is loaded
			mapApiLoaded: function () {
				apiLoaded = true;
				google = window.google;

				$.publish('/map/apiLoaded');
			},

			// Utility function to return API status and google object
			getMapApiStatus: function () {
				return {
					loaded: apiLoaded,
					google: google
				};
			}
		};
	}
);