/* app/ui/mobile/show-more */

define(
	[
		'jquery'
	],
	function ($) {

		var selectors = {
			container: '.js-mobile-show-more',
			moreLink: '.js-mobile-show-more-link',
			lessLink: '.js-mobile-show-less-link',
			content: '.js-mobile-show-more-content'
		};

		var ShowMore = {
			init: function () {
				ShowMore._initEvents();
			},

			_initEvents: function () {
				$(document)
					.on('click', selectors.moreLink, ShowMore._processMoreLinkClick)
					.on('click', selectors.lessLink, ShowMore._processLessLinkClick);
			},

			_processMoreLinkClick: function (e) {
				e.preventDefault();

				var $container = $(this).closest(selectors.container);

				$container.find(selectors.moreLink).hide();
				$container.find(selectors.lessLink).show();
				$container.find(selectors.content).show();
			},

			_processLessLinkClick: function (e) {
				e.preventDefault();

				var $container = $(this).closest(selectors.container);

				$container.find(selectors.moreLink).show();
				$container.find(selectors.lessLink).hide();
				$container.find(selectors.content).hide();
			}
		};

		return ShowMore;

	}
);