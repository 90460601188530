/* app/ui/nav/menu */

define(
    [
        'jquery'
    ],

    function ($) {
        var Menu;

        return {
            init: function () {
                Menu = this;

                $('.js-megamenu-toggle').on('click', Menu._handleMenuToggle);

                $('.js-megamenu-back').on('click', function () {
                    $(".js-megamenu-toggle[data-toggle='" + $(this).data('toggle') + "']").each(Menu._forceMenuClose);
                    document.querySelector('ul.megamenu').scrollIntoView({ behavior: 'smooth' });
                });

                $('#main-content').on('click', function (e) {
                    if (e.isTrigger) return; // Ignore triggered events
                    $(".js-megamenu-toggle[data-toggle-group='top']").each(Menu._forceMenuClose);
                });
            },

            _handleMenuToggle: function (event) {
                event.preventDefault();

                var toggle_target = $(this).data('toggle');
                var toggle_group = $(this).data('toggle-group');

                // Close anything else in the target group
                $("[data-toggle-group='" + toggle_group + "']").not(this).each(Menu._forceMenuClose);

                // Toggle state of the triggered element
                $(this).attr('aria-expanded', function (i, attr) { return attr == 'true' ? 'false' : 'true' });

                $target = $('#' + toggle_target);
                $target.toggleClass('is-open');

                // Sync other legacy events
                Menu._syncLegacyEvents($(this))
            },

            _syncLegacyEvents: function ($tgl) {
                // When a top menu group is open, #main-content should have the nav-open class
                if ($tgl.is(".js-megamenu-toggle[data-toggle-group='top']")) {
                    $tgl.is("[aria-expanded='true']") ?
                        $('#main-content').addClass('nav-open') :
                        $('#main-content').removeClass('nav-open');
                }

                // Change the search icon to an X
                if ($tgl.is(".js-megamenu-toggle[data-toggle='megamenu-search-mobile']")) {
                    $tgl.is("[aria-expanded='true']") ?
                        $tgl.addClass('is-active iconf-close').removeClass('iconf-search') :
                        $tgl.addClass('iconf-search').removeClass('is-active iconf-close');
                }

                // Change the menu icon to an X
                if ($tgl.is(".js-megamenu-toggle[data-toggle='megamenu-root']")) {
                    $tgl.is("[aria-expanded='true']") ?
                        $tgl.addClass('is-open') :
                        $tgl.removeClass('is-open');
                }
            },

            _forceMenuClose: function (_, toggle) {
                var $tgl = $(toggle);
                var toggle_target = $tgl.data('toggle');

                $tgl.attr('aria-expanded', false);

                $target = $('#'+toggle_target);
                $target.removeClass('is-open');

                Menu._syncLegacyEvents($tgl);
            }
        };
    }
);