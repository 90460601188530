/* Hand-rolled modern (2021) version of pubsub extension to jQuery
*   Includes debug(aka verbose) logging to help debug missing trigger or subscribe
*  */

class Event{
    constructor(){
        this.events = {};
    }

    on(eventName, fn) {
        console.debug(`PubSub Event.subscribe ${eventName}`);
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(fn);
    }

    off(eventName, fn) {
        console.debug(`PubSub Event.unsubscribe ${eventName}`);
        if (this.events[eventName]) {
            for (let i = 0; i < this.events[eventName].length; i++) {
                if (this.events[eventName][i] === fn) {
                    this.events[eventName].splice(i, 1);
                    break;
                }
            }
        }
        else {
            console.info(`PubSub - no subscriber for ${eventName}`);
        }
    }

    trigger(eventName, ...data) {

        if (this.events[eventName]) {
            this.events[eventName].forEach(function(fn) {
                console.debug(`PubSub Event.trigger ${eventName}`);
                fn(...data);
            });
        }
    }
}

(function ($) {

    window.topics = window.topics || new Event();

    $.subscribe = function (topic, fn) {
        window.topics.on(topic, fn);
    };

    $.unsubscribe = function () {
        window.topics.off.apply(window.topics, arguments);
    };

    $.publish = function () {
        window.topics.trigger(...arguments);
    };

}(jQuery));
