/* app/ui/tooltip/tooltip */

define(
	[
		'jquery',
		'hoverIntent'
	],
	function ($) {

		var Tooltip;
		var $body;
		var $tooltip;
		var $triggers;
		var tooltipOffset;
		var noTouch;
		var closeTimer;

		return {
			init: function () {
				Tooltip = this;
				$body = $('body');
				$tooltip = $('<div class="tooltip--bubble" />');
				$triggers = $('.js-tooltip');
				tooltipOffset = 10;
				noTouch = $('.no-touch').length;

				$body.append($tooltip);
				Tooltip._initSubscriptions();
				Tooltip._initEvents();
			},

			_initSubscriptions: function() {
				$.subscribe('/tooltip/rebind', Tooltip._rebindTriggers);
			},

			_initEvents: function () {
				if (noTouch) {
					$triggers.hoverIntent({
						over: Tooltip._onTooltipTrigger,
						out: Tooltip._onTriggerMouseout,
						interval: 125
					});
					$tooltip.on('mouseenter', Tooltip._onTooltipHover);
					$tooltip.on('mouseleave', Tooltip._hideTooltip);
				} else {
					$triggers.on('click', Tooltip._onTooltipTrigger);
					$tooltip.on('click', '.js-tooltip-close', Tooltip._hideTooltip);
					$(document).on('click', Tooltip._hideTooltipIfOutside);
				}

				$triggers.on('focusin', Tooltip._onTooltipTrigger);
				$triggers.on('focusout', Tooltip._hideTooltip);
			},

			_rebindTriggers: function() {
				$triggers = null;
				$triggers = $('.js-tooltip');
				Tooltip._initEvents();
			},

			_onTooltipTrigger: function (event) {
				event.preventDefault();
				var $trigger = $(this);
				
				if (!noTouch) {
					event.stopPropagation();
					Tooltip._hideTooltip();
				}
				Tooltip._populateTooltip($trigger);
				Tooltip._positionTooltip($trigger);
				Tooltip._showTooltip();
			},

			_onTriggerMouseout: function () {
				closeTimer = window.setTimeout(Tooltip._hideTooltip, 250);
			},

			_onTooltipHover: function () {
				window.clearTimeout(closeTimer);
			},

			_hideTooltip: function () {
				$tooltip.removeClass('is-visible').empty();
			},

			_hideTooltipIfOutside: function (e) {
				var $target = $(e.target);

				if ($target.closest('.js-tooltip').length === 0) {
					// Clicked outside of a tooltip
					Tooltip._hideTooltip();
				}
			},

			_populateTooltip: function ($trigger) {
				var $content = $trigger.find('.js-tooltip-body').clone();
				$tooltip.html($content);
			},

			_positionTooltip: function ($trigger) {
				var triggerPosition = Tooltip._getTriggerPosition($trigger);
				var tooltipDimensions = Tooltip._getTooltipDimensions();
				var tooltipSide = Tooltip._getTooltipSide(triggerPosition, tooltipDimensions);
				var tooltipPosition = Tooltip._getTooltipPosition(triggerPosition, tooltipDimensions, tooltipSide);

				$tooltip.removeClass('tooltip--left tooltip--right');
				if (tooltipPosition.right !== undefined) {
					$tooltip.css({
						'right': tooltipPosition.right,
						'left':'',
						'top': tooltipPosition.top
					}).addClass('tooltip--' + tooltipSide);
				} else {
					$tooltip.css({
						'left': tooltipPosition.left,
						'right':'',
						'top': tooltipPosition.top
					}).addClass('tooltip--' + tooltipSide);
				}
				if ($triggers.attr('data-attr-fixed-position-mobile') === 'true') {
					$tooltip.addClass('fixed-position-mobile');
				}
			},

			_showTooltip: function () {
				$tooltip.addClass('is-visible');
			},

			_getTriggerPosition: function ($trigger) {
				var $triggerPos = $trigger.offset();
				var triggerDimensions = {
					left: $triggerPos.left,
					top: $triggerPos.top,
					width: $trigger.width(),
					height: $trigger.height()
				};
				var midpoint = {
					x: triggerDimensions.left,
					y: triggerDimensions.top,
					xMid: (triggerDimensions.left + (triggerDimensions.width / 2)),
					yMid: (triggerDimensions.top + (triggerDimensions.height / 2))
				};

				return midpoint;
			},

			_getTooltipDimensions: function () {
				var dimensions = {
					width: $tooltip.width(),
					height: $tooltip.height()
				};

				return dimensions;
			},

			_getTooltipSide: function (triggerPos, dimensions) {
				var side = 'top';

				if ((triggerPos.y - (dimensions.height + tooltipOffset)) < 10) {
					side = 'bottom';
				}

				return side;
			},

			_getTooltipPosition: function (triggerPos, dimensions, side) {
				var position;
				var leftPosValue = (triggerPos.xMid - (dimensions.width / 2));
				var rightPosValue = (triggerPos.xMid + (dimensions.width / 2));
				var topPos = (triggerPos.y - (dimensions.height + tooltipOffset));

				if (side === 'left') {
					leftPosValue = (triggerPos.x - (dimensions.width + tooltipOffset));
				}
				if (rightPosValue > $(window).outerWidth()) {
					rightPosValue = 0;
				}
				if (leftPosValue < 0) {
					leftPosValue = 0;
				}
				if (topPos < 0) {
					topPos = 0;
				}

				if (rightPosValue === 0) {
					position = {
						right: rightPosValue + 'px',
						top: topPos + 'px'
					};
				} else {
					position = {
						left: leftPosValue + 'px',
						top: topPos + 'px'
					};
				}
				return position;
			}
		};
	}
);