/* app/ui/map/map.control */

define(
	[
		'jquery',
		'pubsub'
	],
	function ($) {

		var MapControl;
		var $maps;
		var maps = [];

		return {
			init: function ($elms) {
				MapControl = this;
				$maps = $elms.filter('[data-map-type="control"]');

				MapControl._initSubscriptions();
			},

			_initSubscriptions: function () {
				$.subscribe('/map/apiLoaded', MapControl._processMaps);
			},

			_processMaps: function () {

				for (var i = 0; i < $maps.length; i++) {
					var mapCanvas = $maps[i];
					var $thisMap = $maps.eq(i);
					var latitude = $thisMap.attr('data-lat');
					var longitude = $thisMap.attr('data-lng');
					var zoom = parseInt($thisMap.attr('data-zoom'), 10);
					var myLocation = { latitude: parseFloat(latitude), longitude: parseFloat(longitude) };
					var latLng = new google.maps.LatLng(myLocation.latitude, myLocation.longitude);

					var mapOptions = {
						zoom: zoom || 12,
						center: latLng,
						draggable: !Modernizr.touch,
						streetViewControl: false,
						scrollwheel: false,
						mapTypeControl: true,
						panControl: false,
						panControlOptions: {
							position: google.maps.ControlPosition.LEFT_CENTER
						},
						zoomControl: true,
						zoomControlOptions: {
							style: google.maps.ZoomControlStyle.LARGE,
							position: google.maps.ControlPosition.RIGHT_BOTTOM
						}
					};

					var map = new google.maps.Map(mapCanvas, mapOptions);

					var marker = new google.maps.Marker({
						position: latLng,
						map: map
					});

					var mapData = {
						map: map,
						options: mapOptions,
						location: myLocation
					};

					maps.push(mapData);
				}
			}
		};
	}
);