/* app/ui/nav/load */

define(
	[
		'jquery',
		'util/mediaqueries',
		'app/ui/nav/menu'
	],
	function( $, MediaQueries, menu ) {

		var NavLoad;

		return {
			init: function () {
				NavLoad = this;

				NavLoad._initListenPopups();
				menu.init();
			},

			_initListenPopups: function () {
				// Not live
				$('.js-audio-notlive').magnificPopup({
					type: 'inline',
					midClick: true,
					mainClass: 'mfp-embed-code',
					closeBtnInside: true
				});

				// Live
				$('body').on('click', '.js-audio-play', function (event) {
					event.preventDefault();
					window.open(this.href, 'Radio New Zealand', 'height=286, width=416, addressBar=no, toolbar=no, menubar=no, status=0, location=0');
					$(this).closest('.media').find('.js-ajax-video').trigger('click');
				});
			}
		};

	}
);