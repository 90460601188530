/* app/ui/video/vimeo */
import Player from '@vimeo/player';
import $ from 'jquery';
import 'pubsub';

let Vimeo;
export default {

		init: function () {
			Vimeo = this;
			const $vimeoThumbnails = Vimeo._getThumbnailElements();

			if ($vimeoThumbnails.length) {
				Vimeo.getVimeoThumbnail($vimeoThumbnails);
			}

			$.subscribe('get/vimeoThumb', (data) => Vimeo.getVimeoThumbnail(data ? data[0] : undefined));

		},

		loadVideo: function (elem) {
			if(Vimeo === undefined) {
				Vimeo = this;
			}

			const autoplay = Vimeo._getAutoplayData(elem);
			const options = {
				autoplay: autoplay,
			};
			const id = elem.dataset.videoId;
			if (id.indexOf('showcase/') >= 0) {
				options.url = 'https://vimeo.com/' + id;
			} else {
				options.id = id;
			}

			new Player($(elem).parent(), options);
		},

		getVimeoThumbnail: function ($vimeoThumbnails) {
			if (!$vimeoThumbnails) {
				$vimeoThumbnails = Vimeo._getThumbnailElements();
			}
			$vimeoThumbnails.each(function () {
				const id = Vimeo._getVideoId(this);
				let imageWidth = "";
				let imageHeight = "";

				const imageSize = Vimeo._getImageSize(this);
				if (imageSize) {
					const size = imageSize.split("x");
					imageWidth = size[0];
					imageHeight = size[1];
				}


				const $imgElement = $(this);
				if (id.indexOf('showcase/') >= 0) {
					$.getJSON('https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/' + id,
						{ format: "json" },
						function (data) {
							const featuredImgUrl = data.thumbnail_url;
							// no image size options
							$imgElement.attr("src", featuredImgUrl);

						}).error(function (error) { console.log(error); });
				} else {
					$.getJSON('https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/' + id + '?width=' + imageWidth + '&height=' + imageHeight,
						{ format: "json" },
						function (data) {

							const featuredImgUrl = data.thumbnail_url;
							Vimeo._getElementsById(id).each(function (i, elem) {
								$(elem).attr("src", featuredImgUrl);
							});

						}).error(function (error) { console.log(error); });
				}
			});
		},

		_getThumbnailElements: function () {
			return $('.js-vimeo-thumbnail');
		},

		_getVideoId: function (element) {
			let id = element.dataset.vimeoid;
			// The id should never be falsy so this is fine
			if (!id) {
				id = element.dataset.videoid;
			}
			return id;
		},

		_getImageSize: function (element) {
			let imageSize = element.dataset.vimeoSize;

			if (imageSize === undefined) {
				imageSize = element.dataset.videoSize;
			}

			// Use the container size
			if (imageSize === undefined) {
				imageSize = element.offsetWidth + "x" + element.offsetHeight
			}

			return imageSize;
		},

		_getAutoplayData: function (element) {
			let autoplay = element.dataset.vimeoAutoplay;
			if (autoplay === undefined) {
				autoplay = element.dataset.autoplay;
			}
			return autoplay;
		},

		_getElementsById: function (id) {
			const vimeoSelector = '.js-vimeo-thumbnail[data-videoid="' + id + '"]';
			const videoSelector = '.js-vimeo-thumbnail[data-vimeoid="' + id + '"]';
			return $(vimeoSelector + ',' + videoSelector);
		}
}